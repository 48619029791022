<template>
  <div class="gmv-users">
    <div class="gmv-users-container">
      <div class="gmv-users-container__cell">
        <label>Usuario:</label>
        <InputText
          class="gmv-users-container__input"
          type="text"
          id="usuario"
          placeholder="Usuario"
          v-model="userInfo.sub"
          required
        />
        <label>Contraseña:</label>
        <InputText
          class="gmv-users-container__input"
          type="password"
          placeholder="******"
          required
          disabled
        />
        <div>
          <div>
            <p
              class="gmv-users-container__nav"
              @click="changePasswordFlag = !changePasswordFlag"
            >
              Cambiar contraseña
            </p>
          </div>
          <div class="gmv-users-container-password" v-if="changePasswordFlag">
            <div id="msg"></div>
            <div id="error" class="alert alert-danger ocultar" role="alert">
              Las Contraseñas no coinciden, vuelve a intentarlo!
            </div>
            <div id="ok" class="alert alert-success ocultar" role="alert">
              Las Contraseñas coinciden ! (Procesando cambio de contraseña ...)
            </div>
            <div
              onsubmit="putupdatePassword();return false"
              class="gmv-users-container-password"
            >
              <label for="pass1">Nueva contraseña:</label>
              <InputText
                class="gmv-users-container__input"
                type="password"
                id="pass1"
                placeholder="Nueva contraseña"
                v-model="users.new_password"
                required
              />
              <label for="pass2">Repita contraseña:</label>
              <InputText
                class="gmv-users-container__input"
                type="password"
                id="pass2"
                placeholder="Repita la nueva contraseña"
                @keyup.enter="putupdatePassword"
                required
              />
              <div class="gmv-users-container-button">
                <button
                  type="submit"
                  id="login"
                  class="gmv-users-container__button"
                  @click="putupdatePassword"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <section v-if="verifiedUSer" class="gmv-users-container__files-key">
          <button 
            class="gmv-users-container__files-key--button"
            v-b-modal.modal-center
            @click="generateFilesKey"
            v-if="!hasSecretVersion">
            Generar contraseña de archivos
          </button>
          <button 
            class="gmv-users-container__files-key--button"
            v-b-modal.modal-center
            @click="updateFilesKey"
            v-if="hasSecretVersion">
            Actualizar contraseña de archivos
          </button>
          <b-modal 
            id="modal-center"
            title="Contraseña de archivos"
            content-class="shadow"
            centered
            no-fade
            hide-backdrop
            hide-footer
            @hidden="goHome">
            <template class="gmv-users-container__key-modal">
              <p class="gmv-users-container__key-modal--text">
                Esta es su contraseña para acceder a los archivos:
              </p>
              <Loading v-if="isLoading" />
              <p v-if="!isLoading" class="gmv-users-container__key-modal--key">{{ renderingKey }}</p>
            </template>
          </b-modal>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import Loading from "@/components/Loading";
export default {
  inject: ["reload"],
  name: "user",
  components: {
    InputText,
    Loading
  },
  data() {
    return {
      users: {
        new_password: null,
      },
      changePasswordFlag: false,
      renderingKey: "",
      hasSecretVersion: false,
      validationInfo: {},
      verifiedUSer: null,
      isLoading: false,
    };
  },
  async created() {
    this.userInfo = this.$store.getters["login/showUserInfo"];
    let validation;

    try {
      let userName = this.userInfo;
      const response = await this.$store.dispatch(
        "users/getUserInfo",
        userName
      );
      validation = response.secret_version_id;
      this.verifiedUSer = response.is_verified;
    } catch (e) {
      this.hasSecretVersion = false;
    }

    if (validation) {
      this.hasSecretVersion = true;
    } else {
      this.hasSecretVersion = false;
    }
  },
  methods: {
    async putupdatePassword() {
      let pass1 = document.getElementById("pass1");
      let pass2 = document.getElementById("pass2");
      if (pass1.value != pass2.value) {
        document.getElementById("error").classList.add("mostrar");
        return false;
      } else {
        document.getElementById("error").classList.remove("mostrar");
        document.getElementById("ok").classList.remove("ocultar");
        let result = await this.$store.dispatch(
          "updatePassword/putupdatePassword",
          this.users
        );
        localStorage.clear();
        this.$router.push({ name: "Login" });
        return true;
      }
    },
    async generateFilesKey() {
      this.isLoading = true;
      let key = await this.$store.dispatch(
        "filesKey/generateFilesKey",
        this.users
      );
      this.isLoading = false;
      this.renderingKey = key.secret_key;
    },
    async updateFilesKey() {
      this.isLoading = true;
      let updatedKey = await this.$store.dispatch(
        "filesKey/updateFilesKey",
        this.users
      );
      this.isLoading = false;
      this.renderingKey = updatedKey.secret_key;
    },
    goHome() {
      this.$router.push({
        path: "/home",
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.gmv-users-container {
  display: flex;
  justify-content: center;
  &__cell {
    display: flex;
    flex-direction: column;
    text-align: initial;
    width: 40rem;
    margin: 22rem;
    margin-top: 2rem;
    margin-bottom: 8rem;
  }
  &__input {
    border-radius: 12px;
    margin: 10px;
    height: 2.8rem;
    text-align: center;
  }
  &__button {
    background-color: $color-primary-company;
    color: white;
    margin-right: 15px;
    border: 2px solid $color-primary-company;
    border-radius: 15px;
    height: 3rem;
    width: 8rem;
  }
  &__button:hover {
    background-color: red;
    color: black;
  }
  &__nav {
    color: $color-primary-company;
    display: flex;
    justify-content: flex-end;
    width: 39rem;
    cursor: pointer;
  }
  &__nav:hover {
    color: red;
    text-decoration: underline;
  }
  &__files-key {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 50px 0;
    padding: 10px 5px;

    &--button {
      width: 45%;
      height: 45px;
      border-radius: 25px;
      border: 1px solid $color-primary-company;
      background-color: $color-primary-company;
      color: #fff;
    }

    &--button:hover {
      background-color: red;
    }
  }
  &__key-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--text {
      font-size: 18px;
    }
    &--key {
      font-size: 16px;
      color: $color-primary-company;
      align-self: center;
      justify-self: center;
    }
  }
}
label {
  color: black;
}
.gmv-users-container-password {
  display: flex;
  flex-direction: column;
}
.gmv-users-container-button {
  display: flex;
  justify-content: flex-end;
  width: 40.3rem;
}
.ocultar {
  display: none;
}

.mostrar {
  display: block;
}
</style>